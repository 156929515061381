import React, { useState, useEffect, FormEvent } from 'react'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../../states/UserState'
import { User } from '../../models/user'
import {
  checkBackupCodes,
  getBackupCodes,
  login,
  resetPassword,
  setupSmsDevice,
  setupTotpDevice,
  sendSms,
  hasSavedBackupCodes,
  setSavedBackupCodes,
  confirmOtp,
  microsoftSsoLogin,
} from '../../services/requests'
import QRCodeSetup from './loginComponents/QRCode'
import SmsForm from './loginComponents/SmsForm'
import Login from './loginComponents/Login'
import ForgotPassword from './loginComponents/ForgotPassword'
import CheckEmail from './loginComponents/CheckEmail'
import OtpForm from './loginComponents/OtpForm'
import SetupTwoFactor from './loginComponents/SetupTwoFactor'
import BackupCodes from './loginComponents/BackupCodes'

import logo from '../../resources/images/Eperoto-logo-blue.svg'
import DoneScreen from './loginComponents/DoneScreen'
import { RequestError } from '../../models/enums'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import {
  deepCloneObject,
  isDevEnv,
  logActivity,
} from '../../services/commonFunctions'
import { errorMessages } from '../../services/textFunctions'
import useWindowSize from '../../customHooks/useWindowSize'
import {
  getScaleForPopUp,
  getTopLeftForPopUp,
} from '../../services/popUpPositionFunctions'
import { featuresState } from '../../states/FeaturesState'

type Props = {
  setTrackerInfo: (user: User) => void
  setAsFirstTimeLogin: () => void
  loadingFeatures: boolean
}

export type PageType =
  | 'Login'
  | 'OtpForm'
  | 'SmsForm'
  | 'BackupCodes'
  | 'ForgotPassword'
  | 'CheckEmail'
  | 'QRCode'
  | 'SetupTwoFactor'
  | 'Done'

export default function UserPopup(props: Props) {
  const [user, setUser] = useRecoilState(userState)
  const features = useRecoilValue(featuresState)
  const { width, height } = useWindowSize()

  const [lastSmsTimer, setLastSmsTimer] = useState<number | undefined>(
    undefined,
  )
  const [adminLogin, setAdminLogin] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneRegion, setPhoneRegion] = useState('')
  const [tempUser, setTempUser] = useState<User | undefined>(undefined)
  const [tempTokens, setTempTokens] = useState(['', ''])
  const [tfaToken, setTfaToken] = useState('')
  const [forLostDevice, setForLostDevice] = useState(false)
  const [forSms, setForSms] = useState(false)
  const [firstTimeSms, setFirstTimeSms] = useState(false)
  const [backupCodes, setBackupCodes] = useState(['', '', ''])
  const [url, setUrl] = useState<string>('')
  const [pageType, setPageType] = useState<PageType>('Login')
  const [loading, setLoading] = useState(false)
  const [microsoftLoading, setMicrosoftLoading] = useState(false)

  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >(undefined)
  const [heightOfBackground, setHeightOfBackground] = useState(
    window.innerHeight < 750 ? 750 : '100vh',
  )
  const [errors, setErrors] = useState<string[]>([])
  const currentUrl = window.location.href

  const setHandlingErrors = useSetRecoilState(handlingErrorsState)

  const reportWindowSize = () => {
    setHeightOfBackground(window.innerHeight < 750 ? 750 : '100vh')
  }

  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const fakeWait = 200

  useEffect(() => {
    if (
      features.use_microsoft_sso &&
      (currentUrl.includes('.eperoto.com/#/adminLogin') ||
        currentUrl === 'http://localhost:3000/#/adminLogin')
    ) {
      setAdminLogin(true)
    } else {
      if (adminLogin && pageType !== 'Login') {
        setTimeout(() => {
          setAdminLogin(false)
          console.log('now it is closed')
        }, 4000)
      }
    }

    if (features.use_microsoft_sso && !user.isLoggedIn()) {
      localStorage.setItem(
        'redirectUrl',
        window.location.hash.replace('#/', ''),
      )
    }

    // eslint-disable-next-line
  }, [currentUrl, features, user])

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize)

    return () => {
      window.removeEventListener('resize', reportWindowSize)
    }
    // eslint-disable-next-line
  }, [window.innerHeight])

  useEffect(() => {
    if (!lastSmsTimer) {
      setLastSmsTimer(undefined)
    } else {
      setTimeout(() => {
        setLastSmsTimer((prevState) => (prevState ? prevState - 1 : undefined))
      }, 1000)
    }

    // eslint-disable-next-line
  }, [lastSmsTimer])

  const changeBackupCode = (index: number, value: string) => {
    const tempTokens = [...backupCodes]
    tempTokens[index] = value.trim()
    setBackupCodes(tempTokens)
  }

  const onBackButton = () => {
    setValidationMessage(undefined)
    setForLostDevice(false)
    setOtp('')
    if (firstTimeSms) {
      setPage('SmsForm')
    }
  }

  const setPage = (type: PageType, other?: any) => {
    switch (type) {
      case 'Login':
        setEmail('')
        setPassword('')
        break
      case 'OtpForm':
        setBackupCodes(['', '', ''])
        if (!isDevEnv() || !other) {
          setOtp('')
        }
        setForSms(other)
        if (other) {
          setLastSmsTimer(59)
        }
        break
      case 'ForgotPassword':
        setEmail('')
        break
      case 'SetupTwoFactor':
        setForLostDevice(false)
        setForSms(false)
        setFirstTimeSms(false)
        break
      case 'QRCode':
        setOtp('')
        setUrl(other ?? '')
        break
      case 'SmsForm':
        setFirstTimeSms(true)
        setPhoneNumber('')
        setPhoneRegion('')
        break
      case 'BackupCodes':
        setBackupCodes(other ?? ['', '', ''])
        break
    }
    setLoading(false)
    setValidationMessage(undefined)
    setPageType(type)
  }

  const onSetupTotp = () => {
    setLoading(true)
    setValidationMessage(undefined)
    setTimeout(() => {
      setupTotpDevice(tfaToken).then((res) => {
        if ('errorCode' in res) {
          setLoading(false)
          setValidationMessage(
            errorMessages(user.settings).SETUP_TWO_FACTOR_ERROR,
          )
        } else {
          setValidationMessage(undefined)
          setPage('QRCode', res.data.url)
        }
      })
    }, fakeWait)
  }

  const onSetupSms = () => {
    setLoading(true)
    setTimeout(() => {
      setPage('SmsForm')
    }, fakeWait)
  }

  const onBackupCodesDone = () => {
    setLoading(true)
    setValidationMessage(undefined)

    setTimeout(() => {
      setSavedBackupCodes(tempTokens[0]).then((res) => {
        if ('errorCode' in res) {
          setLoading(false)
          setValidationMessage(
            errorMessages(user.settings).SETUP_TWO_FACTOR_ERROR,
          )
        } else {
          setValidationMessage(undefined)
          setPage('Done')
        }
      })
    }, fakeWait)
  }

  const onFinishSetup = () => {
    if (tempUser) {
      const newUser = deepCloneObject(tempUser)
      setUser(newUser)

      if (!tempUser.checklist.introVideo) {
        props.setAsFirstTimeLogin()
      }

      props.setTrackerInfo(tempUser)
    }
  }

  const loginSumbit = () => {
    setLoading(true)
    setValidationMessage(undefined)
    setHandlingErrors({
      fatal: false,
      bad_request: false,
      timeout: false,
      server_error: false,
      unauthorized: false,
      not_exist: false,
      forbidden: false,
      wrong_reduced_dates: false,
    })
    setTimeout(() => {
      login({ username: email, password: password }).then(async (res) => {
        if ('errorCode' in res) {
          setLoading(false)
          if (res.errorCode === RequestError.SERVER_ERROR) {
            setValidationMessage(errorMessages(user.settings).SERVER_ERROR)
          } else if (res.errorCode === RequestError.TIMEOUT) {
            setValidationMessage(errorMessages(user.settings).LOGIN_FAILED)
          } else {
            setValidationMessage(errorMessages(user.settings).WRONG_CREDENTIALS)
          }
        } else {
          if (!res.data.deviceType) {
            setTfaToken(res.data.tempToken)
            setPage('SetupTwoFactor')
          } else if (res.data.deviceType === 'sms') {
            setTfaToken(res.data.tempToken)
            const res2 = await sendSms(res.data.tempToken)

            if ('errorCode' in res2) {
              setValidationMessage(errorMessages(user.settings).SEND_SMS_FAILED)
              setLoading(false)
            } else {
              if (isDevEnv()) {
                setOtp(res2.data.code)
              }
              setPage('OtpForm', true)
            }
          } else if (res.data.deviceType.includes('temp')) {
            setTfaToken(res.data.tempToken)
            setPage('SetupTwoFactor')
          } else {
            setTfaToken(res.data.tempToken)
            setPage('OtpForm')
          }
        }
      })
    }, fakeWait)
  }

  const sendAgainSms = () => {
    sendSms(tfaToken)
    setLastSmsTimer(59)
  }

  const otpFormSubmit = () => {
    if (!forLostDevice) {
      if (otp !== '') {
        setLoading(true)
        setValidationMessage(undefined)

        setTimeout(() => {
          confirmOtp(
            {
              otp: otp,
            },
            tfaToken,
          ).then((res) => {
            if ('errorCode' in res) {
              if (
                res.errorCode === RequestError.TIMEOUT ||
                res.errorCode === RequestError.SERVER_ERROR
              ) {
                setLoading(false)
                setValidationMessage(
                  errorMessages(user.settings).OTP_REQUEST_FAILED,
                )
              } else if (
                res.errorCode === RequestError.LOCKED_ACCOUNT ||
                res.errorCode === RequestError.FORBIDDEN ||
                res.errorCode === RequestError.UNAUTHORIZED
              ) {
                setPage('Login')
              } else {
                setLoading(false)
                setValidationMessage(
                  forSms
                    ? errorMessages(user.settings).WRONG_CODE_SMS
                    : errorMessages(user.settings).WRONG_CODE,
                )
              }
            } else {
              const newUser = User.UserFromDB(res.data)
              setFirstTimeSms(false)
              setTempUser(newUser)
              setTempTokens([res.data.token, res.data.refresh_token])
              //Mixpanel 1
              logActivity(false, 'Logged in')

              if (!res.data.has_backup) {
                getBackupCodes(res.data.token).then((res2) => {
                  if ('errorCode' in res2) {
                    setUser(newUser)
                    props.setTrackerInfo(newUser)
                    setLoading(false)
                    setValidationMessage(
                      errorMessages(user.settings).GET_BACK_UP_CODES_FAILED,
                    )
                  } else {
                    setPage('BackupCodes', res2.data.codes)
                  }
                })
              } else {
                hasSavedBackupCodes(res.data.token).then((res2) => {
                  if ('errorCode' in res2) {
                    setLoading(false)
                    setUser(newUser)
                    props.setTrackerInfo(newUser)
                    setValidationMessage(
                      errorMessages(user.settings)
                        .HAS_SAVED_BACK_UP_CODES_FAILED,
                    )
                  } else {
                    if (res2.data.saved) {
                      setLoading(false)
                      setUser(newUser)
                      props.setTrackerInfo(newUser)
                    } else {
                      getBackupCodes(res.data.token).then((res3) => {
                        if ('errorCode' in res3) {
                          setLoading(false)
                          setUser(newUser)
                          props.setTrackerInfo(newUser)
                          setValidationMessage(
                            errorMessages(user.settings)
                              .GET_BACK_UP_CODES_FAILED,
                          )
                        } else {
                          setPage('BackupCodes', res3.data.codes)
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }, fakeWait)
      } else {
        setValidationMessage(errorMessages(user.settings).NOT_ENOUGH_DIGITS)
      }
    } else {
      setLoading(true)
      setValidationMessage(undefined)
      setTimeout(() => {
        checkBackupCodes(
          {
            codes: backupCodes,
          },
          tfaToken,
        ).then((res) => {
          if ('errorCode' in res) {
            setLoading(false)
            setValidationMessage(errorMessages(user.settings).WRONG_BACKUP)
          } else {
            setPage('SetupTwoFactor')
          }
        })
      }, fakeWait)
    }
  }

  const forgotPasswordSubmit = () => {
    setLoading(true)
    setValidationMessage(undefined)

    setTimeout(() => {
      resetPassword({
        email: email,
      }).then((res) => {
        if ('errorCode' in res) {
          setValidationMessage(
            errorMessages(user.settings).RESET_PASSWORD_FAILED,
          )
          setLoading(false)
        } else {
          setPage('CheckEmail')
        }
      })
    }, fakeWait)
  }

  const SmsFormSubmit = () => {
    setLoading(true)
    setValidationMessage(undefined)
    setTimeout(() => {
      setupSmsDevice(
        {
          phoneNumber: phoneNumber,
          phoneRegion: phoneRegion,
        },
        tfaToken,
      ).then((res) => {
        if (!('errorCode' in res)) {
          sendSms(tfaToken).then((res2) => {
            if (!('errorCode' in res2)) {
              setPage('OtpForm', true)
            } else if (
              res2.errorCode === RequestError.TIMEOUT ||
              res2.errorCode === RequestError.SERVER_ERROR
            ) {
              setLoading(false)
              setValidationMessage(errorMessages(user.settings).SEND_SMS_FAILED)
            } else {
              setLoading(false)
              setValidationMessage(errorMessages(user.settings).WRONG_NUMBER)
            }
          })
        } else if (
          res.errorCode === RequestError.TIMEOUT ||
          res.errorCode === RequestError.SERVER_ERROR
        ) {
          setLoading(false)
          setValidationMessage(
            errorMessages(user.settings).SETUP_SMS_DEVICE_FAILED,
          )
        } else {
          setLoading(false)
          setValidationMessage(errorMessages(user.settings).WRONG_NUMBER)
        }
      })
    }, fakeWait)
  }

  const validation = () => {
    let numOfErrors = 0
    const newErrors: string[] = []

    switch (pageType) {
      case 'Login':
        if (!email) {
          newErrors.push('logInEmail')
          numOfErrors++
        } else if (!emailPattern.test(email)) {
          newErrors.push('logInEmail:validation')
          numOfErrors++
        }

        if (!password) {
          newErrors.push('logInPassword')
          numOfErrors++
        }
        break
      case 'OtpForm':
        if (forLostDevice) {
          backupCodes.forEach((code, index) => {
            if (!code) {
              newErrors.push(`otp${index}`)
              numOfErrors++
            }
          })
        }
        break
      case 'ForgotPassword':
        if (!email) {
          newErrors.push('resetEmail')
          numOfErrors++
        } else if (!emailPattern.test(email)) {
          newErrors.push('resetEmail:validation')
          numOfErrors++
        }
        break
      case 'SmsForm':
        if (!phoneNumber) {
          newErrors.push('phoneNumber')
          numOfErrors++
        }
        break
    }

    setErrors([...newErrors])
    return numOfErrors === 0
  }

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    if (!validation()) {
      return
    }

    switch (pageType) {
      case 'Login':
        loginSumbit()
        if (adminLogin) {
          window.location.href = window.location.href.replace('/adminLogin', '')
        }
        break
      case 'QRCode':
      case 'OtpForm':
        otpFormSubmit()
        break
      case 'ForgotPassword':
        forgotPasswordSubmit()
        break
      case 'SmsForm':
        SmsFormSubmit()
        break
    }
  }

  const handleMicrosoftSsoLogin = () => {
    setMicrosoftLoading(true)
    microsoftSsoLogin(
      user.microsoftAuthorizedButNotAUser ? 'choose_account' : 'direct',
    ).then((res) => {
      if (!('errorCode' in res)) {
        window.location.href = res.data.redirect_url
      } else {
        setMicrosoftLoading(false)
      }
    })
  }

  return (
    <>
      <div className="userPopUp" style={{ height: heightOfBackground }}>
        <div className="header-out">
          <div className="header">
            <a className="navbar-brand" href="https://eperoto.com/">
              <img className="logo" src={logo} alt="Eperoto" />
            </a>
          </div>
        </div>
        <div
          className={`loginBox ${
            pageType === 'Login' && features.use_microsoft_sso && !adminLogin
              ? 'ssoLoginBox'
              : ''
          } ${user.microsoftAuthorizedButNotAUser ? 'ssoError' : ''}`}
          style={{
            transform: `scale(${getScaleForPopUp(
              width,
              height,
              550,
              620,
              pageType === 'Login' && features.use_microsoft_sso && !adminLogin,
            )})`,
            top: `${
              getScaleForPopUp(
                width,
                height,
                550,
                620,
                pageType === 'Login' &&
                  features.use_microsoft_sso &&
                  !adminLogin,
              ) >= 1
                ? 15
                : getTopLeftForPopUp(
                    width,
                    height,
                    550,
                    620,
                    pageType === 'Login' &&
                      features.use_microsoft_sso &&
                      !adminLogin,
                  )[1]
            }px`,
            left: `${
              getTopLeftForPopUp(
                width,
                height,
                550,
                620,
                pageType === 'Login' &&
                  features.use_microsoft_sso &&
                  !adminLogin,
              )[0]
            }px`,
          }}
        >
          <form className="form" onSubmit={handleSubmit}>
            {pageType === 'Login' ? (
              <Login
                email={email}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
                setPage={setPage}
                validationMessage={validationMessage}
                loading={loading}
                microsoftLoading={microsoftLoading}
                errors={errors}
                handleMicrosoftSsoLogin={handleMicrosoftSsoLogin}
                admin={adminLogin}
                loadingFeatures={props.loadingFeatures}
              />
            ) : pageType === 'OtpForm' ? (
              <OtpForm
                backupCodes={backupCodes}
                setOtp={setOtp}
                otp={otp}
                validationMessage={validationMessage}
                changeBackupCode={changeBackupCode}
                setForLostDevice={() => {
                  setValidationMessage(undefined)
                  setForLostDevice(true)
                }}
                forLostDevice={forLostDevice}
                forSms={forSms}
                firstTimeSms={firstTimeSms}
                onBackButton={onBackButton}
                loading={loading}
                timeFromLastSms={lastSmsTimer}
                sendAgainSms={sendAgainSms}
                errors={errors}
              />
            ) : pageType === 'SetupTwoFactor' ? (
              <SetupTwoFactor
                onSetupTotp={onSetupTotp}
                onSetupSms={onSetupSms}
                loading={loading}
                setupTwoFactorError={validationMessage}
              />
            ) : pageType === 'SmsForm' ? (
              <SmsForm
                validationMessage={validationMessage}
                setPhoneNumber={setPhoneNumber}
                setPhoneRegion={setPhoneRegion}
                setPageType={setPage}
                loading={loading}
                errors={errors}
              />
            ) : pageType === 'QRCode' ? (
              <QRCodeSetup
                onSubmit={handleSubmit}
                setPageType={setPage}
                url={url}
                setOtp={setOtp}
                validationMessage={validationMessage}
                loading={loading}
              />
            ) : pageType === 'BackupCodes' ? (
              <BackupCodes
                loading={loading}
                onDone={onBackupCodesDone}
                backupCodes={backupCodes}
                savedBackUpCodesFailed={validationMessage}
              />
            ) : pageType === 'ForgotPassword' ? (
              <ForgotPassword
                email={email}
                setPageType={setPage}
                setEmail={setEmail}
                loading={loading}
                validationMessage={validationMessage}
                errors={errors}
              />
            ) : pageType === 'CheckEmail' ? (
              <CheckEmail setPageType={setPage} />
            ) : pageType === 'Done' ? (
              <DoneScreen onFinishSetup={onFinishSetup} />
            ) : null}
          </form>
        </div>
      </div>
    </>
  )
}
